* {
    margin: 0px;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Teko', sans-serif;

    line-height: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');


/* 
 All heading Name
 1. All Header text 
 2. Home Slider Css 
 3. Section 3 WnAService
 4. Section 4 ServiceSection_main 
*/


/* <-------------------- 1. All Header text ---------------------- > */
.what {
    color: grey;
    font-size: 1.4rem;
}

.header_text_all h1 {
    color: rgba(57, 51, 123, 1);
    font-size: 3rem;
}

.nav-item {
    letter-spacing: 1px;
    font-size: x-large;
}

/* <-------------------- 2. Home Slider Css ---------------- > */
.home-slider .slick-next {
    right: 10px !important;
    display: none !important;
}

.home-slider .slick-prev {
    left: 18px !important;
    z-index: 999;
    display: none !important;
}

.home-slider .slick-dots li {
    display: block;
}

.home-slider .slick-dots {
    margin: 0;
    list-style: none;
    text-align: center;
    width: auto;
    position: absolute;
    right: 40px;
    top: 60%;
    transform: translateY(-50%);
}

.home-slider .slick-next:before,
.slick-prev:before {
    color: #DC202F;
    z-index: 999 !important;
}

/* <-------------------- 3. Section 3 WnAService ---------------- > */
.wnaservice_main {
    margin-top: 1rem;
}

.wna_leftMain {
    position: relative;
}

.wna_main_fixImg {
    text-align: center;
    position: absolute;
    top: calc(36% - 50px);
    border: 1px;
    border-radius: 16px;
    left: calc(66% - 50px);
    /* background-color: white; */
    overflow: hidden;
}

.wna_right_h2 {
    /* text-align: center; */
    font-size: 2.5rem;
    letter-spacing: 1px;
    line-height: 3rem;
    word-spacing: 3px;
    color: darkblue;
    z-index: 99999;
}

.wna_right_p {
    text-align: justify;
    font-size: 1.3rem;
    line-height: 24px;
    word-spacing: 2px;
}

.wna_right_Button {
    width: 100%;
    margin-top: 68px;
}

@media screen and (max-width:768px) {
    .wna_right_Button {
        width: 50%;
        text-align: center;
        margin-top: 20px;
    }

    .width_768 {
        display: flex;
        justify-content: center;
    }
}

.wna_right_Button button {
    border: 2px solid black;
    font-size: 23px;
    padding: 5px 20px;
}

.sideImage {
    position: relative;
}

.wna_sideDign {
    position: absolute;
    left: 0;
    top: calc(4% - 35px);
    filter: opacity(50%);
}

.wna_sideDign2 {
    position: absolute;
    left: 0;
    top: calc(33% - -63px);
    left: -12px;
    filter: opacity(50%);
}

/* <-------------------- 4. Section 4 ServiceSection_main --------------------- > */
.ServiceSection_main {
    margin-top: 18px;
    margin-bottom: 40px;
}

.boxshadow {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.ServiceSection_main_divAll {
    min-height: 310px;
}

.ServiceSection_main .h3 {
    color: rgba(57, 51, 123, 1);
    font-weight: 600;
    font-size: 2.5rem;
    text-align: start;
}

.ServiceSection_main_divAll p {
    font-size: 1.3rem;
    letter-spacing: 0.5px;
    word-spacing: 1px;
    width: 68%;
}

.ServiceSection_main_divAll h3 {
    margin-top: 1vw;
}

.ServiceSection_Sub_img_One {
    width: 100px;
    position: absolute;
    top: -29px;
    right: 41px;
}

.ServiceSection_Sub_img_Two {
    width: 100px;
    position: absolute;
    top: -29px;
    right: 115px;
}

.ServiceSection_main_Two_right {
    width: 60%;
}

.ServiceSection_main_Two_right p {
    width: 100%;
}

.ServiceSection_Sub_img_Three {
    width: 100px;
    position: absolute;
    top: 174px;
    right: 51px;
}

.ServiceSection_main_Three_right {
    width: 60%;
    /* background-color: #afafaf; */

}

.ServiceSection_Sub_img_Four {
    width: 100px;
    position: absolute;
    top: 174px;
    right: 99px;
}

.ServiceSection_Sub_Four {
    /* background-color: #39337B; */
    width: 60%;
}

.ServiceSection_Sub_Four p {
    /* background-color: #39337B; */
    width: 100%;
}

/* responsiv  */

.ServiceSection_main_divOneposition {
    display: block !important;

}






/* <-------------------- 4  Section 4 ServiceSection_main  Over---------------- > */


/* .h1 {
    color: rgba(57, 51, 123, 1);
    font-weight: 600;
    font-size: 4vw;
} */

.Hover {
    border-radius: 100% !important;
    transition: all 1s ease;
}

.Hover:hover {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.416) !important;
    transform: rotate(1turn) !important;
}

.in {
    width: 5vw !important;
    position: relative;
    border-radius: 50%;
}



/* poster css */
.poster {
    background-image: url("../public/Images/Group\ 29.png");
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-size: contain;

}



/* Why Should Choose Our Agency? */

.Pragraph {
    width: 50%;
    font-size: larger;
    text-align: justify;
    position: relative;
}


/* CF_Background_Image */
.CF_Background_Image {
    background-image: url("./../public/Images/wallpaper.png");
    background-size: cover;
    background-repeat: no-repeat;
    height: 500px;
    /* background-color: #3D3D3D; */
    background-position: 300px 500px;
}



.imgTwo {
    /* height: 70vh; */
    width: 100%;
}

.Trust-line {
    font-size: larger;
    width: 100%;
    background-color: rgb(241, 241, 241);
    border-left: 5px solid #DC202F;
    border-radius: 5px;
    padding: 10px;
    /* margin-top: 20px; */
}

.Two-img {
    position: relative;
    width: 49.3%;
}

.Two-img::after {
    content: "BEST DIGITAL AGENCY IN 2023";
    position: absolute;
    top: 25%;
    right: -125px;
    color: #DC202F;
    font-size: 0.7em;
    font-weight: 600;
    font-family: Rubik;
    padding: 14px 20px;
    border: 2px solid rgba(57, 51, 123, 1);
    border-top: 0;
    transform: rotate(-90deg);
    letter-spacing: 1px;
    white-space: nowrap;
}

/* Question section */

.question-sec {
    width: 100%;
    background-image: url("../public/Images/image\ 1.png");
}

.que-No {
    color: #DC202F;
}

.que-list {
    background-color: #d9d9d9;
    color: rgba(57, 51, 123, 1);
    font-size: 1.8rem;
}

.percetn-round {
    align-items: center;
    justify-content: center;
    font-size: 22px;
    font-weight: 500;
    color: rgba(57, 51, 123, 1);
    width: 50%;
    /* height: 17vh; */
    text-align: center;
    border-radius: 50%;
}

@media screen and (max-width:768px) {
    .percetn-round {
        align-items: center;
        justify-content: center;
        font-size: 52px;
        font-weight: 500;
        color: rgba(57, 51, 123, 1);
        width: 30%;
        /* height: 17vh; */
        text-align: center;
        border-radius: 50%;
    }


    /* .precent-text {
        font-size: 2vw;
        font-weight: 500;
    } */

}

.precent-text {
    font-size: 2rem;
    font-weight: 500;
}



/* City section  */
.City-sec {
    width: 100%;
    background-image: url("../public/Images/modern-3d-white-paper-style-background\ 1.png");
}

.city-img-content {
    border: 1px solid #c1c1c1;
    width: 38%;
    border-radius: 7px;
    box-shadow: 0px 3px 3px #afafaf;
    background-color: white;
}




.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    /* Adjust the opacity value as needed */
    pointer-events: none;
    /* Allows clicks to pass through the overlay to the image */
}


input,
textarea {
    width: 100%;
    margin-bottom: 2.1vw;
    border-radius: 4px;
    border: none;
    padding: 10px 8px;
    background-color: #EFEFEF;
}

::placeholder {
    color: #3D3D3D;
    font-family: sans-serif;
    position: relative;
    top: 2px;
    font-size: 1em;
    font-weight: bolder;
}

#eml,
#tel {
    width: 49%;
}

#tel {
    float: right;
}




/* HappyCutomers */
.hc_main {
    width: 100%;
    background-image: url("./../public/Images/happy\ clint\ wallpaper.png");
    background-position: center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.headerText {
    margin: 3rem 0;
}

/* three main box  */
.patison_3 {
    /* background-color: cadetblue; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10rem 0;

}

.con {
    font-size: 1.2vw !important;
    padding: 10px;
}

@media screen and (max-width:768px) {
    .con {
        font-size: 2vw !important;
        padding: 7px;
    }
}

@media screen and (max-width:440px) {
    .con {
        font-size: large !important;
        padding: 7px;
    }
}

.pc,
.ac,
.Hc {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    width: 400px;
    /* background-color: #3D3D3D; */
}

.pc_img {
    position: relative;
    display: block;
    width: 140px;
    height: 140px;
    background: #EAEAF1;
    border-radius: 50%;
    background-image: url("./../public/svg/complete.svg");
    background-position: 50% 50%;
    background-size: 47%;
    background-repeat: no-repeat;
    padding: 2px;
    background-repeat: no-repeat;
    /* box-shadow:
        0 0 0 5px white,
        0 0 0 9px red; */
}

/* .man {
    /* border-top: 3px solid red; */
/* padding: 3px;
    /* background-color: white; */
/* border-radius: 50%; */
/* } */

.man {
    /* border: 3px solid red ;
    border-radius: 50%;
    z-index: 2; */
    /* width: 100%;
    height: 100px; 
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    border: 10px solid gray;
    border-bottom: 0; */
}

/* .borders{
    border: 3px solid red ;
    border-radius: 50%;
    height: 100%;
    width: 100%;
} */

.ac_img {
    position: relative;
    display: block;
    width: 140px;
    height: 140px;
    background: #EAEAF1;
    border-radius: 50%;
    background-image: url("./../public/svg/man.svg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    padding: 2px;
    background-size: 48%;
    background-repeat: no-repeat;
    /* box-shadow:
        0 0 0 5px white,
        0 0 0 9px red; */
}

.Hc_img {
    position: relative;
    display: block;
    width: 140px;
    height: 140px;
    background: #EAEAF1;
    border-radius: 50%;
    background-image: url("./../public/svg/customer-review.svg");
    background-position: 50% 50%;
    background-size: 48%;
    background-repeat: no-repeat;
    padding: 2px;
    background-repeat: no-repeat;
    /* box-shadow:
        0 0 0 5px white,
        0 0 0 9px red; */
}

.pc_text h2,
.ac_text h2,
.Hc_text h2 {
    color: #3D3D3D;
    margin-top: 23px;
    font-size: 2.8em;
}

.OP50 {
    filter: opacity(50%) !important;
}

.pc_text p,
.ac_text p,
.Hc_text p {
    color: red;
    margin-top: -20px;
    font-size: 2rem;
}



/* Footer css  */
.footermain {
    font-family: 'Rubik', sans-serif;
    /* background-color: #afafaf !important; */
    background-image: url(../public/svg/footer.svg);
    background-size: cover;
    background-position: center;
}

footer h5 {
    color: black;
    font-size: 1.5rem;
    font-weight: 600;
    font-family: 'Rubik', sans-serif;
    margin-bottom: 10px;
}

.footer_left p {
    font-size: 1.16em;
    text-align: justify;
    font-weight: 500;
    height: 7rem;
    height: auto;
    font-family: 'Rubik', sans-serif;
    line-height: 31px;
}

.footer_left {
    padding: 2rem 0rem;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    align-items: center;
}

.footerLogo {
    width: 100%;
}

.footerLogo img {
    width: 12rem;
}

.footerIcon {
    width: 100%;
}

.iconReper {
    height: 50px;
    width: 50px;
    background-color: #3D3D3D;
    color: white;
    font-size: 1.4rem;
    margin-right: 0.9rem;
    padding-top: 5px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconReper a {
    color: white;
    font-family: "sans-serif"
}

.contact {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.contact i {
    font-size: 1.2rem;
    color: red;
}

.contact p {
    font-size: 1.1rem;
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    margin: 6px 10px;
}

.FooterAdd {
    font-size: 1rem !important;
}

.footerRight {
    padding-left: 1rem;
}

.footerlink {
    text-decoration: none;
    font-size: 1.1em;
    font-family: "sans-serif";
    font-family: 'Rubik', sans-serif;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-direction: column;
}

.footerLink_a {
    text-decoration: none;
    padding-left: 3px;
    color: #DC202F;
}

.footer_Top_line {
    content: "";
    display: block;
    width: 60%;
    height: 2px;
    border-radius: 7px;
    background: whitesmoke;
    margin-bottom: 8px;
}

@media screen and (max-width: 768px) {
    .footer_right_one {
        text-align: center;
    }

    .rightContect {
        text-align: center;
        margin-left: 20px;
    }

}













/* ARTWAVE TECHNOLOGY ACCORDION */

.artwave-tech {
    width: 100%;
    background-image: url("../public/Images/Group\ 29.png");
}

.custom-accordion .accordion-item {
    border-radius: 0 !important;
    /* Set border-radius to 0 or any desired value */
}

#accordionExample {
    width: 79%;
}

.contactitem {
    position: relative;
    top: 0vw;
    padding-top: 0.6vw;
    padding-bottom: 0.1vw;
    background-color: #DC202F;
    color: white;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    /* width: 100vw; */
    font-size: 2vw;
}

.contactitem span {
    font-size: 1.8em;
    color: white;
    text-align: center;
    background-color: #DC202F;
    font-weight: 800;
}

.contactbtn1 {
    width: 14vw;
}




@media screen and (max-width: 768px) {
    .FS {
        font-size: 10vw !important;
    }

    .contactitem span {
        font-size: 3.4em;
        color: white;
        text-align: center;
        font-weight: 800;
        background-color: #DC202F;
    }

    .contactbtn {
        width: 215px;
        background-color: #DC202F;
    }

    .contactbtn2 {
        width: 58%;
        display: flex;
        justify-content: center;
        margin-top: 10px;

    }

    .FooterAdd {
        font-size: .9rem !important;
    }

}


.gm-style .place-card-large {
    background-color: #39337B !important;
}

.accordion-button:not(.collapsed) {
    color: rgb(57, 51, 123);
    background-color: white;
    border: white !important;
    box-shadow: white !important;
}

/* .accordion-button:focus {
    z-index: 3;
    border-color: white;
    outline: 0;
    box-shadow: white;
  } */

h2 .accordion-button:focus {
    z-index: 3;
    border-color: white;
    outline: 0;
    box-shadow: 0 0 10px rgba(255, 255, 255, 1) !important;
}


.accordion-body {
    font-size: 1.7vw;
}



/* DISTINCTIVENESS section */

.DISTINCTIVENESS-sec {
    width: 100%;
    background-image: url("../public/Images/wallpaper\ our\ distinctiveness.png");
}

.distin-content {
    width: 38vw;
    height: 28vh;
    /* border: 1px solid black; */
    border-radius: 10px;
    margin-bottom: 2rem;
    margin: 1rem;
    box-shadow: #393381 0px 0px 10px 0px;
}

@media screen and (max-width:768px) {
    .distin-content {
        width: 38vw;
        height: 20vh;
        border-radius: 10px;
        margin-bottom: 2rem;
        margin: 1rem;
        box-shadow: #393381 0px 0px 10px 0px;
    }
}









/* About Elevate  */
.exMargin {
    margin-bottom: -20px !important;
}

.About_backgroundImg {
    background-image: url('./../public/Images/wallpaper\ Elevate\ with\ Top-tier\ Design\ Solutions..png');
    background-repeat: repeat-y;
    background-size: cover;
    padding: 0;
    padding-bottom: 80px;
}

.Elevate_Repor {
    position: relative;
    overflow: hidden;
}

.Right_Bc_Imag {
    position: absolute;
    right: -59px;
    z-index: -1;
}

.Right_Bc_Imag img {
    width: 85%;
}

.Elevate_left h2 {
    color: #39337B;
    font-size: 4em;
}

.Elevate_left h2 span {
    color: red;
}

.Elevate_left .button {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    font-size: 4em;
}

.Elevate_left .button button {
    font-size: 0.3em;
    /* width: 50%; */
    /* margin-right: 20px; */
}

.borderButton {
    border-top: 1px solid red !important;
    border-radius: 0;
}

/* .borderButton:hover {
    background-color: #dc3545;
    color: white !important;
} */

.about-two-button .discover {
    background-color: #dc3545 !important;
    color: white;
}

.about-two-button .col-5 {
    margin: 0.3em;
}

.Elevate_left .text p,
.Elevate_right .text p {
    text-align: justify;
    margin-top: 15px;
    font-size: 1rem;
    line-height: 24px;
    word-spacing: -3px;
    font-family: sans-serif;
    font-weight: 500;

}

/* card  */
.cardheader {
    display: flex;
    align-items: center;
    margin-top: 5px;

}

.card_shadow {
    box-shadow: #393381 1px 1px 12px -2px;
    margin: 4px;
    height: 100%;
}

.cardheader img {
    margin: 8px 0px;
    margin-right: 20px;
}

.cardheader h5 {
    font-size: 1.7em;
    margin: 0;
    padding: 0;
    color: #DC202F;
}

.cardtext {
    font-size: 1.1em;
    padding: 25px 3px 17px;
    font-family: Rubik;
    line-height: 1.5em;
    font-weight: 600;
    color: #3D3D3D;

}

.cart_raper {
    position: relative;
}

.cart_raper_sideDign {
    size: 100px;
    position: absolute;
    top: 257px;
}

.cart_raper_sideDign1 {
    size: 100px;
    position: absolute;
    bottom: 300px;
}

.cart_raper_sideDign3 {
    size: 100px;
    position: absolute;
    top: 300px;
    right: 0px;
}

.Right_Bc_Imag1 {
    position: absolute;
    right: 0px;
    top: 53%;
    z-index: -1;
}

.A_text {
    /* font-size: 3em !important; */
    top: calc(50% - 42px);
}


.Home_backgroundImg {
    background-image: url('./../public/Images/wallpaper\ CUSTOMER\ FEEDBACKS.png');
    background-repeat: repeat-y;
    background-size: cover;
    padding: 0;
}

/* slider Css  */
.setimage {
    z-index: 2;
}

.setimage_span {
    /* background-color: white; */
    width: 200px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid red;
    z-index: 2 !important;
}



.slider_reper {
    background-color: #39337B;
    border-radius: 12px;
    z-index: 2 !important;
    margin: 40px;
    border-top: 7px solid red;

}

.slider_reper .sHeader .bigIcon {
    display: flex;
    flex-wrap: wrap;
}

.slider_reper .sHeader {
    position: relative;
    padding: 10px 5px;
}

.slider_reper .sHeader .bigIcon .google {
    height: 25px;
}

.slider_reper .sHeader .bigIcon .man {
    position: absolute;
    top: -47px;
    left: 39px;
}



.slider_reper .sHeader .bigIcon .name {
    margin-left: 125px;
    color: white;
    font-size: 2em;

}

.slider_reper .sHeader .bigIcon .star {
    height: 30px;
    margin-left: 15px;
}

.dhruv-image {
    width: 50%;
}



/* Your Project */

/* .feedback-img{
    z-index: 999999999999;
    width: 8vw;
    height: 15vh;
    margin-top: 10px;
    border-radius: 360px;
} */



.your-project {
    padding: 4.8rem 10rem;
    background-color: #DC202F;
    color: white;
    border: none;
    border-radius: 10px;
}

.contact-btn {
    border: none;
    color: white;
    background-color: rgba(57, 51, 123, 1);
    border-radius: 10px;
}

.elementor-widget-container {
    border-radius: 10px !important;
}


/* POSTER 1 CSS */
.posterfont {
    position: absolute;
    left: 15rem;
}

.wna_leftMain .wna_main_fixImg img {
    border: .3rem solid rgb(255, 0, 0);
}


.IMG_MAIN {
    width: 30px !important;
    height: 30px !important;
    /* border: 1px solid black; */
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

/*  @media screen */
@media screen and (min-width: 993px) {

    /* Section 3 WnAService */
    .wnaservice_main {
        margin-top: 1rem;
    }

    .wna_sideDign {
        position: absolute;
        left: 0;
        top: calc(-2% - 5px);
    }

    .wna_main_Iage1 {
        width: 83% !important;
    }

    .wna_leftMain .wna_main_fixImg img {
        width: 15rem !important;
        border-radius: 16px;
        overflow: hidden !important;
        border: .2rem solid rgb(255, 0, 0);
    }

    .wna_leftMain .wna_main_fixImg {
        text-align: center;
        position: absolute;
        top: calc(37% - 0px);
        border: 1px;
        border-radius: 16px;
        left: calc(66% - 3px);
    }

    .right {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        align-content: center;
    }

    .wna_main_Iage1 {
        width: 83% !important;
    }

    .wna_leftMain .wna_main_fixImg img {
        width: 15rem !important;
        border-radius: 16px;
    }

    .wna_leftMain .wna_main_fixImg {
        text-align: center;
        position: absolute;
        top: calc(37% - 0px);
        border: 1px;
        border-radius: 16px;
        left: calc(66% - 3px);
    }
}

.Sec {
    width: 60%;
}

.FFS {
    font-size: 3.7vw;
}


@media screen and (max-width: 768px) {

    .IMG1 {
        height: 1.2em !important;
        max-width: max-content !important;
    }

    .FFS {
        font-size: 5.5vw;
    }

    .Sec {
        width: 100%;
    }

    .post_h1 {
        font-size: x-large !important;
    }

    /* Section 3 WnAService  */
    .wna_leftMain .wna_main_fixImg {
        text-align: center;
        position: absolute;
        top: calc(37% - 0px);
        border: 1px;
        border-radius: 16px;
        left: calc(66% - 3px);
    }

    .wna_main_Iage1 {
        width: 83% !important;
    }

    .wna_leftMain .wna_main_fixImg img {
        width: 15rem !important;
        border-radius: 16px;
    }


    .Pragraph {
        width: 100%;
        position: relative;
    }

    .Two-img {
        position: relative;
        width: 100% !important;
        /* height: 50vh !important; */
    }

    .imgTwo {
        height: 40vh !important;
        width: 100% !important;
    }

    .Two-img::after {
        font-family: Rubik;
        font-size: 0.9em;
        font-weight: 500;
        position: relative;
        right: -5.5rem !important;
        bottom: 0;
        top: 1rem !important;
        padding: 1rem !important;
        height: 2rem !important;
        transform: rotate(0deg);
    }


    .two-imgSec {
        display: flex !important;
        flex-wrap: wrap !important;
        justify-content: center !important;
    }

    .city-img-content {
        border: 1px solid #c1c1c1;
        width: 60%;
        border-radius: 7px;
        box-shadow: 0px 3px 3px #afafaf;
        background-color: white;
        margin: 0 !important;
        margin: 1rem !important;
    }

    .posterfont {
        left: 3 rem;
    }


    .distin-content {
        width: 90%;
        height: auto !important;
        float: left !important;
    }

    .your-project {
        padding: 1.2rem 3rem !important;
        /* border-radius: 0 !important; */
    }

    .project-container,
    .project-container .container {
        padding: 0 !important;
    }



    .wna_right_p {
        font-size: 1rem;
        line-height: 22px;
        word-spacing: 1.5px;
    }


    .accordion-body {
        font-size: 5vw;
        padding: 13px;
    }


    .btn-view-details .btn {
        padding: 1rem 1.6rem;
    }

    .contact-content {
        font-size: 0.9em !important;
    }

    .perent {
        font-size: 0.7em !important;
        line-height: normal;

    }

    /* .feedback-img{
        z-index: 999999999999;
        width: 20vw;
        height: 10vh;
        margin-top: 10px;
        margin-left: 10px;
        border-radius: 360px;
    } */
}

@media screen and (max-width: 576px) {

    /* Section 3 WnAService  */
    .wna_main_Iage1 {
        position: relative;
        width: 60vw !important;
    }

    .wna_leftMain .wna_main_fixImg img {
        width: 98% !important;
        border-radius: 16px;
    }

    .wna_leftMain .wna_main_fixImg {
        text-align: center;
        position: absolute;
        top: calc(37% - 3px);
        width: 30%;
        border: 1px;
        border-radius: 16px;
        left: calc(59% - 31px);
    }

    /* Section 4 ServiceSection_main   */
    .ServiceSection_main_divAll p {
        width: 100%;
    }

    .ServiceSection_main_divOneposition {
        position: static !important;
        display: block !important;
        display: flex !important;
        flex-direction: row-reverse;
    }


    .ServiceSection_main_Two_reposition {
        position: static !important;
        display: block !important;
        display: flex !important;
        background-color: #39337B;

    }

    .ServiceSection_main_divTwo_reposition {
        display: flex !important;
    }


    .ServiceSection_main_Two_right {
        width: 100%;
    }

    .ServiceSection_main_Three_reposition {
        position: static !important;
        display: block !important;
    }

}

@media screen and (max-width: 1000px) {
    .distin-content {
        height: 45vh !important;
    }
}

@media screen and (max-width: 770px) {
    .distin-content {
        height: auto !important;
    }
}


@keyframes slide {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}