*,
:after,
:before {
    box-sizing: border-box
}

:where(.is-layout-flex) {
    gap: .5em
}

:where(.is-layout-grid) {
    gap: .5em
}

:where(.wp-block-columns.is-layout-flex) {
    gap: 2em
}

:where(.wp-block-columns.is-layout-grid) {
    gap: 2em
}

:where(.wp-block-post-template.is-layout-flex) {
    gap: 1.25em
}

:where(.wp-block-post-template.is-layout-grid) {
    gap: 1.25em
}

:where(.wp-block-post-template.is-layout-flex) {
    gap: 1.25em
}

:where(.wp-block-post-template.is-layout-grid) {
    gap: 1.25em
}

:where(.wp-block-columns.is-layout-flex) {
    gap: 2em
}

:where(.wp-block-columns.is-layout-grid) {
    gap: 2em
}

body {
    --extendify--spacing--large: var(--wp--custom--spacing--large, clamp(2em, 8vw, 8em)) !important;
    --wp--preset--font-size--ext-small: 1rem !important;
    --wp--preset--font-size--ext-medium: 1.125rem !important;
    --wp--preset--font-size--ext-large: clamp(1.65rem, 3.5vw, 2.15rem) !important;
    --wp--preset--font-size--ext-x-large: clamp(3rem, 6vw, 4.75rem) !important;
    --wp--preset--font-size--ext-xx-large: clamp(3.25rem, 7.5vw, 5.75rem) !important;
    --wp--preset--color--black: #000 !important;
    --wp--preset--color--white: #fff !important;
    --wp--preset--color--black: #000;
    --wp--preset--color--cyan-bluish-gray: #abb8c3;
    --wp--preset--color--white: #fff;
    --wp--preset--color--pale-pink: #f78da7;
    --wp--preset--color--vivid-red: #cf2e2e;
    --wp--preset--color--luminous-vivid-orange: #ff6900;
    --wp--preset--color--luminous-vivid-amber: #fcb900;
    --wp--preset--color--light-green-cyan: #7bdcb5;
    --wp--preset--color--vivid-green-cyan: #00d084;
    --wp--preset--color--pale-cyan-blue: #8ed1fc;
    --wp--preset--color--vivid-cyan-blue: #0693e3;
    --wp--preset--color--vivid-purple: #9b51e0;
    --wp--preset--gradient--vivid-cyan-blue-to-vivid-purple: linear-gradient(135deg, rgba(6, 147, 227, 1) 0%, #9b51e0 100%);
    --wp--preset--gradient--light-green-cyan-to-vivid-green-cyan: linear-gradient(135deg, #7adcb4 0%, #00d082 100%);
    --wp--preset--gradient--luminous-vivid-amber-to-luminous-vivid-orange: linear-gradient(135deg, rgba(252, 185, 0, 1) 0%, rgba(255, 105, 0, 1) 100%);
    --wp--preset--gradient--luminous-vivid-orange-to-vivid-red: linear-gradient(135deg, rgba(255, 105, 0, 1) 0%, #cf2e2e 100%);
    --wp--preset--gradient--very-light-gray-to-cyan-bluish-gray: linear-gradient(135deg, #eee 0%, #a9b8c3 100%);
    --wp--preset--gradient--cool-to-warm-spectrum: linear-gradient(135deg, #4aeadc 0%, #9778d1 20%, #cf2aba 40%, #ee2c82 60%, #fb6962 80%, #fef84c 100%);
    --wp--preset--gradient--blush-light-purple: linear-gradient(135deg, #ffceec 0%, #9896f0 100%);
    --wp--preset--gradient--blush-bordeaux: linear-gradient(135deg, #fecda5 0%, #fe2d2d 50%, #6b003e 100%);
    --wp--preset--gradient--luminous-dusk: linear-gradient(135deg, #ffcb70 0%, #c751c0 50%, #4158d0 100%);
    --wp--preset--gradient--pale-ocean: linear-gradient(135deg, #fff5cb 0%, #b6e3d4 50%, #33a7b5 100%);
    --wp--preset--gradient--electric-grass: linear-gradient(135deg, #caf880 0%, #71ce7e 100%);
    --wp--preset--gradient--midnight: linear-gradient(135deg, #020381 0%, #2874fc 100%);
    --wp--preset--font-size--small: 13px;
    --wp--preset--font-size--medium: 20px;
    --wp--preset--font-size--large: 36px;
    --wp--preset--font-size--x-large: 42px;
    --wp--preset--spacing--20: .44rem;
    --wp--preset--spacing--30: .67rem;
    --wp--preset--spacing--40: 1rem;
    --wp--preset--spacing--50: 1.5rem;
    --wp--preset--spacing--60: 2.25rem;
    --wp--preset--spacing--70: 3.38rem;
    --wp--preset--spacing--80: 5.06rem;
    --wp--preset--shadow--natural: 6px 6px 9px rgba(0, 0, 0, .2);
    --wp--preset--shadow--deep: 12px 12px 50px rgba(0, 0, 0, .4);
    --wp--preset--shadow--sharp: 6px 6px 0px rgba(0, 0, 0, .2);
    --wp--preset--shadow--outlined: 6px 6px 0px -3px rgba(255, 255, 255, 1), 6px 6px rgba(0, 0, 0, 1);
    --wp--preset--shadow--crisp: 6px 6px 0px rgba(0, 0, 0, 1)
}

@media only screen and (max-width:768px) {
    :root {
        --woocommerce: #7f54b3;
        --wc-green: #7ad03a;
        --wc-red: #a00;
        --wc-orange: #ffba00;
        --wc-blue: #2ea2cc;
        --wc-primary: #7f54b3;
        --wc-primary-text: #fff;
        --wc-secondary: #e9e6ed;
        --wc-secondary-text: #515151;
        --wc-highlight: #b3af54;
        --wc-highligh-text: #fff;
        --wc-content-bg: #fff;
        --wc-subtext: #767676
    }
}

.elementor-inner-column>div:before,
.elementor-inner-section:before {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    content: "";
    display: block;
    z-index: 0
}

.elementor-element {
    --flex-direction: initial;
    --flex-wrap: initial;
    --justify-content: initial;
    --align-items: initial;
    --align-content: initial;
    --gap: initial;
    --flex-basis: initial;
    --flex-grow: initial;
    --flex-shrink: initial;
    --order: initial;
    --align-self: initial;
    flex-basis: var(--flex-basis);
    flex-grow: var(--flex-grow);
    flex-shrink: var(--flex-shrink);
    order: var(--order);
    align-self: var(--align-self)
}

.elementor-element:where(.e-con-full, .elementor-widget) {
    flex-direction: var(--flex-direction);
    flex-wrap: var(--flex-wrap);
    justify-content: var(--justify-content);
    align-items: var(--align-items);
    align-content: var(--align-content);
    gap: var(--gap)
}

:root {
    --page-title-display: block
}

.elementor-section {
    position: relative
}

.elementor-section .elementor-container {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    position: relative
}

@media (max-width:1024px) {
    .elementor-section .elementor-container {
        flex-wrap: wrap
    }
}

.elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 1140px
}

.elementor-widget-wrap {
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-start
}

.elementor-widget-wrap>.elementor-element {
    width: 100%
}

.elementor-widget {
    position: relative
}

.elementor-widget:not(:last-child) {
    margin-bottom: 20px
}

.elementor-column {
    position: relative;
    min-height: 1px;
    display: flex
}

.elementor-column-gap-extended>.elementor-column>.elementor-element-populated {
    padding: 15px
}

@media (min-width:768px) {
    .elementor-column.elementor-col-50 {
        width: 50%
    }
}

@media (max-width:767px) {
    .elementor-column {
        width: 100%
    }
}

.elementor-element .elementor-widget-container {
    transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, transform var(--e-transform-transition-duration, .4s)
}

.elementor-element {
    --swiper-theme-color: #000;
    --swiper-navigation-size: 44px;
    --swiper-pagination-bullet-size: 6px;
    --swiper-pagination-bullet-horizontal-gap: 6px
}

.elementor-element :where(.swiper-container-horizontal)~.swiper-pagination-bullets {
    bottom: 5px;
    left: 0;
    width: 100%
}

.elementor-element :where(.swiper-container-horizontal)~.swiper-pagination-progressbar {
    width: 100%;
    height: 4px;
    left: 0;
    top: 0
}

.elementor-section.elementor-section-boxed>.elementor-container {
    max-width: 1370px
}

.elementor-widget:not(:last-child) {
    margin-block-end: 20px
}

.elementor-element {
    --widgets-spacing: 20px 20px
}

@media (max-width:1024px) {
    .elementor-section.elementor-section-boxed>.elementor-container {
        max-width: 1024px
    }
}

@media (max-width:767px) {
    .elementor-section.elementor-section-boxed>.elementor-container {
        max-width: 767px
    }
}

@media (prefers-reduced-motion:no-preference) {
    :root {
        scroll-behavior: smooth
    }
}

body {
    margin: 0;
    font-family: var(--bs-font-sans-serif);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important
}

h5 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2
}

h5 {
    font-size: 1.25rem
}

a {
    color: #0d6efd;
    text-decoration: underline
}

a:hover {
    color:rgba(57, 51, 123, 1)
}

img {
    vertical-align: middle
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
    cursor: pointer
}

::-moz-focus-inner {
    padding: 0;
    border-style: none
}

::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-year-field {
    padding: 0
}

::-webkit-inner-spin-button {
    height: auto
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
    padding: 0
}

::file-selector-button {
    font: inherit
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

.clearfix:after {
    display: block;
    clear: both;
    content: ""
}

:root {
    --font-current-theme1: "Syne", sans-serif;
    --font-current-theme2: "Teko", "DM Sans", sans-serif;
    --heading-font-family: var(--font-current-theme2);
    --body-font-family: var(--font-current-theme2);
    --body-font-size: 1rem;
    --body-font-weight: 400;
    --body-font-bold: 500;
    --text-color: #6c6a72;
    --link-color: #352f27;
    --link-hover-color: #5b8c51;
    --entry-meta-color: #67687a;
    --headings-color: #0f0f0f;
    --h1-font-size: 3.5rem;
    --h1-font-size-mob: 3rem;
    --h2-font-size: 2.75rem;
    --h2-font-size-mob: 2.5rem;
    --h3-font-size: 1.9rem;
    --h4-font-size: 1.55rem;
    --h5-font-size: 1.2rem;
    --h6-font-size: 1rem;
    --headings-font-weight-h1: 700;
    --headings-font-weight-h2: 600;
    --headings-font-weight-h3: 600;
    --headings-font-weight-h4: 600;
    --headings-font-weight-h5: 600;
    --headings-font-weight-h6: 600;
    --section-title-color: var(--headings-color);
    --section-title-font-size: var(--h2-font-size);
    --section-title-font-family: var(--heading-font-family);
    --section-title-font-weight: var(--headings-font-weight-h2);
    --section-title-subtitle-color: #6a6a6a;
    --section-title-subtitle-font-size: 18px;
    --section-title-subtitle-font-family: var(--font-current-theme2);
    --section-title-subtitle-font-weight: 400;
    --line-height-widget-li: 2.5rem;
    --btn-font-size: 16px;
    --theme-light-background: #f4f5f8;
    --theme-light-background-text-color: var(--headings-color);
    --news-widget-archive-icon-color: var(--theme-color1);
    --mobile-menu-item-link-text-color: #111;
    --mobile-menu-item-link-bg-color: #f0f0f3;
    --mobile-menu-item-link-bg-border-color: #c8d9d8;
    --mobile-menu-item-link-text-color-hover: #111;
    --mobile-menu-item-indicator-bg-color: var(--theme-color2);
    --mobile-menu-item-indicator-text-color: var(--text-color-bg-theme-color2)
}

body,
html {
    margin: 0;
    padding: 0;
    width: 100%;
    line-height: 150%
}

html {
    font-size: 100%;
    overflow-y: scroll;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent
}

::selection {
    background: #333;
    color: #fff;
    text-shadow: none
}

::-moz-selection {
    background: #333;
    color: #fff;
    text-shadow: none
}

::-webkit-selection {
    background: #333;
    color: #fff;
    text-shadow: none
}

:active,
:focus {
    outline: 0 !important
}

a {
    color: var(--link-color);
    text-decoration: none;
    font-weight: var(--body-font-weight);
    transition: all .3s ease
}

@media (prefers-reduced-motion:reduce) {
    a {
        transition: none
    }
}

a:focus,
a:hover {
    color: var(--hover-theme-color);
    text-decoration: none
}

img {
    max-width: 100%
}

h5 {
    font-family: var(--heading-font-family);
    color: rgba(57, 51, 123, 1)
}

h5 a {
    color: inherit;
    font-weight: inherit
}

h5 {
    line-height: 1.2;
    margin-bottom: 1rem;
    margin-top: .75rem;
    letter-spacing: -.04em
}

h5 {
    font-size: var(--h5-font-size)
}

h5 {
    font-weight: var(--headings-font-weight-h5)
}

html {
    font-size: 100%;
    max-width: 100%
}

@media (max-width:991.98px) {
    html {
        font-size: 98%
    }
}

@media (max-width:767.98px) {
    html {
        font-size: 96%
    }
}

@media (max-width:575.98px) {
    html {
        font-size: 94%
    }
}

html,
html a {
    -webkit-font-smoothing: antialiased
}

body {
    line-height: 2;
    background-color: #fff;
    background-attachment: fixed;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    counter-reset: my-sec-counter;
    max-width: 100%;
    overflow-x: hidden;
    position: relative
}

img {
    height: auto;
    max-width: 100%
}

section {
    position: relative
}

.isotope-layout .isotope-layout-inner {
    position: relative
}

.isotope-layout .isotope-item {
    padding: 0 15px;
    margin: 0 0 30px;
    float: left;
    width: 24.98%
}

.isotope-layout.grid-1 .isotope-item {
    width: 100% !important
}

.isotope-layout .isotope-layout-inner {
    margin: 0 -15px
}

.isotope-layout .isotope-item {
    padding: 0 15px;
    margin: 0 0 30px
}

.isotope-layout.gutter-10 .isotope-item {
    padding: 0 8px;
    margin: 0
}

@media (max-width:767px) {
    .isotope-layout .isotope-item {
        width: 33.2% !important
    }
}

@media (max-width:1024px) {
    .isotope-layout .isotope-item {
        width: 49.98% !important
    }
}

@media (max-width:991.98px) {
    .isotope-layout .isotope-item {
        width: 49.98% !important
    }
}

@media (max-width:767.98px) {
    .isotope-layout .isotope-item {
        width: 100% !important
    }
}

@media (max-width:575.98px) {
    .isotope-layout .isotope-item {
        width: 100% !important
    }
}

:root {
    --theme-color1: #7243f2;
    --theme-color1-rgb: 114, 67, 242;
    --theme-color1-lighter: #9977f6;
    --theme-color1-darker: #4b10ed;
    --theme-color2: #1d1729;
    --theme-color2-rgb: 29, 23, 41;
    --theme-color2-lighter: #362b4d;
    --theme-color2-darker: #040305;
    --theme-color3: #ae8fff;
    --theme-color3-rgb: 174, 143, 255;
    --theme-color3-lighter: #d7c7ff;
    --theme-color3-darker: #8557ff;
    --theme-color4: #87c03d;
    --theme-color4-rgb: 135, 192, 61;
    --theme-color4-lighter: #a1cf67;
    --theme-color4-darker: #69952f;
    --text-color-bg-theme-color1: #fff;
    --text-color-bg-theme-color2: #fff;
    --text-color-bg-theme-color3: #fff;
    --text-color-bg-theme-color4: #fff;
    --hover-theme-color: #7243f2
}

.service-item-current-style3 {
    position: relative;
    margin-bottom: -6px
}

.service-item-current-style3 .inner-box {
    position: relative;
    background-color: #fff;
    box-shadow: 0 10px 60px rgba(0, 0, 0, .1);
    overflow: hidden;
    height: 100%;
    padding: 20px;
    padding-right: 220px;
    min-height: 350px;
    transition: all .3s linear;
    z-index: 2
}

@media (prefers-reduced-motion:reduce) {
    .service-item-current-style3 .inner-box {
        transition: none
    }
}

@media (max-width:1199.98px) {
    .service-item-current-style3 .inner-box {
        padding: 30px !important
    }
}

.service-item-current-style3 .inner-box:after {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(57, 51, 123, 1);
    transform: scale(0);
    transform-origin: right bottom;
    border-radius: 500px 0 0;
    z-index: -1;
    content: "";
    transition: all .3s linear
}

@media (prefers-reduced-motion:reduce) {
    .service-item-current-style3 .inner-box:after {
        transition: none
    }
}

.service-item-current-style3 .inner-box:hover:after {
    transform: scale(1.5)
}

.service-item-current-style3 .inner-box:hover .service-details {
    color: #fff
}

.service-item-current-style3 .inner-box:hover .title a {
    color: #fff
}

.service-item-current-style3 .inner-box:hover .icon {
    transform: rotate(180deg) scale(-1)
}

.service-item-current-style3 .inner-box:hover .image-box .image img {
    transform: scale(1.1)
}

.service-item-current-style3 .image-box {
    position: absolute;
    right: 0;
    bottom: 0
}

@media (max-width:1199.98px) {
    .service-item-current-style3 .image-box {
        position: relative;
        left: 0;
        top: 0;
        margin-bottom: 70px
    }
}

.service-item-current-style3 .image-box .image {
    border-radius: 400px 0 0;
    overflow: hidden;
    max-width: 265px
}

@media (max-width:1199.98px) {
    .service-item-current-style3 .image-box .image {
        border-radius: 0 !important;
        max-width: 100%
    }
}

.service-item-current-style3 .image-box .image img {
    width: 100%;
    transition: all .3s ease
}

@media (prefers-reduced-motion:reduce) {
    .service-item-current-style3 .image-box .image img {
        transition: none
    }
}

.service-item-current-style3 .image-box .icon {
    position: absolute;
    right: 75px;
    top: -10px;
    height: 68px;
    width: 68px;
    /* height: 88px; */
    /* width: 88px; */
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 34px;
    color: var(--theme-color1);
    box-shadow: 0 10px 60px rgba(0, 0, 0, .1);
    border-radius: 50%;
    z-index: 2;
    transition: all .3s ease
}

@media (prefers-reduced-motion:reduce) {
    .service-item-current-style3 .image-box .icon {
        transition: none
    }
}

@media (max-width:1199.98px) {
    .service-item-current-style3 .image-box .icon {
        right: 0 !important;
        left: 0 !important;
        top: auto;
        bottom: 0;
        margin: 0 auto -40px
    }
}

.service-item-current-style3 .content {
    position: relative
}

@media (max-width:1199.98px) {
    .service-item-current-style3 .content {
        text-align: center
    }
}

.service-item-current-style3 .content .service-details {
    max-width: 340px;
    font-size: 20px
}

@media (max-width:1199.98px) {
    .service-item-current-style3 .content .service-details {
        max-width: 100%
    }
}

.service-item-current-style3 .title {
    font-size: 35px;
    margin-top: 0;
    margin-bottom: 15px;
    letter-spacing: 1px
}

@media (max-width:575.98px) {
    .service-item-current-style3 .title {
        font-size: 24px
    }
}

.service-item-current-style3 .title:hover {
    color: var(--theme-color1)
}

.service-item-current-style3 .title a:hover {
    color: #fff
}

.service-item-current-style3 .service-details {
    line-height: 30px;
    transition: all .3s linear
}

@media (prefers-reduced-motion:reduce) {
    .service-item-current-style3 .service-details {
        transition: none
    }
}

.image-position-bottom-left .service-item-current-style3 .inner-box {
    padding-left: 280px;
    padding-right: 50px
}

.image-position-bottom-left .service-item-current-style3 .inner-box:after {
    transform-origin: left bottom;
    border-radius: 0 500px 0 0
}

.image-position-bottom-left .service-item-current-style3 .image-box {
    right: auto;
    left: 0
}

.image-position-bottom-left .service-item-current-style3 .image-box .image {
    border-radius: 0 400px 0 0
}

.image-position-bottom-left .service-item-current-style3 .image-box .icon {
    right: auto;
    left: 75px
}

.image-position-top-right .service-item-current-style3 .inner-box:after {
    transform-origin: right top;
    border-radius: 0 0 0 500px
}

.image-position-top-right .service-item-current-style3 .image-box {
    top: 0;
    bottom: auto
}

.image-position-top-right .service-item-current-style3 .image-box .image {
    border-radius: 0 0 0 400px
}

.image-position-top-right .service-item-current-style3 .image-box .icon {
    top: auto;
    bottom: -10px
}

.image-position-top-left .service-item-current-style3 .inner-box {
    padding-left: 280px;
    padding-right: 30px
}

.image-position-top-left .service-item-current-style3 .inner-box:after {
    transform-origin: left top;
    border-radius: 0 0 500px
}

.image-position-top-left .service-item-current-style3 .image-box {
    bottom: auto;
    top: 0;
    left: 0;
    right: auto
}

.image-position-top-left .service-item-current-style3 .image-box .image {
    border-radius: 0 0 400px
}

.image-position-top-left .service-item-current-style3 .image-box .icon {
    right: auto;
    left: 75px;
    top: auto;
    bottom: -10px
}