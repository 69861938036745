.h_menu{
    position: relative;
    margin-left: 90px;
}

@media (max-width: 768px)  {
    .h_menu{
        position: relative;
        margin-left: 0px;
    }
}